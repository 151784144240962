import { getAutoAddProps, getIgnoredColumnProps, getDocDeclaration, hasColumnsSlot, isAlwaysFunctionProp, components, inlineHtml, childComponents, getColumnsSlotsComponent } from 'o365.modules.CodeBuilder.constants.ts';
import CodeBuilder from 'o365.modules.CodeBuilder.ts';
import { TreeNode } from 'o365.modules.TreeObject.ts';
export default class NodeItem {

    private _declaration: any;

    private _count: number;
    private _componentName: string;
    private _columnsSlotComponenent: string;
    private _htmlString: any;
    private _dataObjects: Array<any>;
    fileName: string;
    node: TreeNode;
    private _parentBuilder: CodeBuilder;
    fields: Array<any> = [];
    parentFileName: string = null;

    props: PropsItemsCollection;
    slots: Array<CustomItem> = [];
    expose: Array<CustomItem> = [];
    events: Array<CustomItem> = [];
    examples: Array<CustomItem> = [];
    defaultSlot: Array<PropItem>;
    htmlString: string;
    item: any;


    get availableChildComponents() {
        return childComponents[this.fileName] ?? [];
    }
    get description() {
        return this._declaration.description;
    }

    get id() {
        return this.item.id;
    }

    get name() {
        return this.item.name ?? this.item.fileName;
    }


    get count() {
        return this._count;
    }

    set count(pValue: number) {
        this._count = pValue;
    }

    get componentName() {
        return this._componentName ?? components[this.fileName];
    }

    get caption() {
        return this.item.name ?? components[this.fileName];
    }

    constructor(pNode: any, pDataObjects: Array<any>, pParentBuilder: CodeBuilder) {
        this._parentBuilder = pParentBuilder;
        this.fileName = pNode.fileName;
        this.item = pNode;
        this._dataObjects = pDataObjects;
        this.count = pNode.count;
        this.props = new PropsItemsCollection(inlineHtml.indexOf(this.fileName) > -1);
        this._htmlString = pNode.htmlString;
        if (hasColumnsSlot(this.fileName)) {
            this._columnsSlotComponenent = getColumnsSlotsComponent(this.fileName);
        }
    }

    async updateDeclarations() {
        if (this.htmlString) {
            console.log(this.htmlString);
        }
        if (!this.fileName) {
            return;
        }
        await getDocDeclaration(this.fileName).then((pDeclaration: any) => {
            this._declaration = pDeclaration;

            if (pDeclaration.props) pDeclaration.props.forEach(prop => {
                if (prop.tags && prop.tags.hasOwnProperty("ignore")) return;

                prop['node'] = this;
                if (this._dataObjects && prop.name === "dataObject") {
                    prop['values'] = this._dataObjects.map(x => x.id);

                }
                if (getIgnoredColumnProps(this.parentFileName).indexOf(prop.name) === -1) {

                    this.props.addProp(prop);
                }
            })
            getAutoAddProps(this.componentName).forEach(prop => {
                prop['node'] = this;
                this.props.addProp(prop)

            });
            if (this._declaration['events'] && this._declaration['events'].length) {



                this._declaration['events'].forEach(prop => {
                    if (prop.tags && prop.tags.hasOwnProperty("ignore")) return;
                    prop['node'] = this;
                    prop['event'] = true;
                    prop['type'] = { name: "boolean" };
                    this.props.addProp(prop);
                })
            }



            if (this._declaration['expose'] && this._declaration['expose'].length) {
                this.props.addProp({
                    node: this,
                    name: "ref",
                    value: "ref_" + this.componentName,
                    description: "Variable needs to be defined in code. Used to access expose component methods",
                    type: { name: "string" }
                });
                this._declaration['expose'].forEach(prop => {
                    if (prop.tags && prop.tags.hasOwnProperty("ignore")) return;
                    prop['node'] = this;
                    this.expose.push(new CustomItem(prop));
                })
            }
            //this._declaration['slots'].filter(slot=>slot.name !== 'default').forEach(prop=>{
            if (this._declaration['slots']) this._declaration['slots'].filter(slot => slot.name !== 'default').forEach(prop => {
                if (prop.tags && prop.tags.hasOwnProperty("ignore")) return;
                prop['node'] = this;
                this.slots.push(new CustomItem(prop));
            })
            if (this._declaration['events']) this._declaration['events'].forEach(prop => {
                if (prop.tags && prop.tags.hasOwnProperty("ignore")) return;
                prop['node'] = this;
                this.events.push(new CustomItem(prop));
            })
            if (this._declaration['tags'] && this._declaration['tags'].hasOwnProperty("examples")) this._declaration['tags']['examples'].forEach(prop => {

                prop['node'] = this;
                this.examples.push(new CustomItem(prop));
            })

        })
    }

    bindDsSelect(pDataObjectID: string) {
        if (!this._dataObjects) return;
        if (!this._columnsSlotComponenent) return;
        this.fields.splice(0, this.fields.length);

        const vDataObject = this._dataObjects.find(x => x.id == pDataObjectID);
        if (vDataObject) {
            vDataObject.fields.forEach(field => {
                this.fields.push(new CheckboxItem({ name: field.name, isSelected: false }, this));
            });

            if (this.name?.endsWith("DataGrid.vue")) {
                this.fields.forEach(f => (f.isSelected = true));
            }            

            this._parentBuilder.dsFields.storage.setItems(this.fields, true);
            this._parentBuilder.dsFields.setData(this._parentBuilder.dsFields.storage.data, true);
        }
    }

    changeHtml(pName: string, pType: string) {

        this._parentBuilder.changeHtml(pName, pType);
    }

    fieldChanged(pField: string, pState: boolean) {
        if (pState) {
            this._parentBuilder.addNode({
                fileName: this._columnsSlotComponenent,
                id: this._getFieldID(pField),
                name: pField + " Field",
                value: pField
            });
        } else {
            this._parentBuilder.removeNode({ id: this._getFieldID(pField) })
        }
    }

    private _getFieldID(pField: string) {
        return this._columnsSlotComponenent + pField;
    }





    //its own field dataobject if needed
}

class PropsItemsCollection {
    props: Array<PropItem> = [];
    inlineHtml: boolean = true;

    constructor(pInlineHtml: boolean = true) {
        this.inlineHtml = pInlineHtml;
    }

    addProp(prop) {
        this.props.push(new PropItem(prop));
    }

    getString() {
        let vCol = "";
        const vUsedProps = this.props.filter(x => x.code !== null);
        if (!this.inlineHtml && vUsedProps.length > 0) vCol += "\r\n";
        if (this.inlineHtml) vCol += " ";
        vUsedProps.forEach((item: any, index: number) => {
            if (!this.inlineHtml) vCol += "    ";
            if (typeof item.code === "string") {
                vCol += item.code;

            } else {
                if (item.code.func) {
                    vCol += ':';
                }
                vCol += item.code.attr;
                vCol += '="';
                if (item.code.translate) vCol += "$t('"
                vCol += item.code.value;
                if (item.code.translate) vCol += "')"
                vCol += '"';

            }
            if (this.inlineHtml && index < vUsedProps.length - 1) {
                vCol += " ";
            }
            if (!this.inlineHtml && index < vUsedProps.length - 1) {
                vCol += "\r\n";
            }


        })

        return vCol;
    }


}

class CheckboxItem {
    private _node: NodeItem;
    item: any;

    get name() {
        return this.item.name;
    }
    get isSelected() {
        return this.item.isSelected;
    }

    set isSelected(pValue) {
        this.item.isSelected = pValue;
        this._node.fieldChanged(this.name, pValue);
    }

    constructor(pItem: any, pNode: NodeItem) {
        this.item = pItem;
        this._node = pNode;
    }
}


class CustomItem {
    private _options: any;
    private _type: string = "slot";
    private _selected: boolean = false;
    private _node: NodeItem;
    private _exampleMakdown: string;
    defaultSlot: boolean = false;

    name: string;

    get selected() {
        return this._selected;
    }

    set selected(pValue: boolean) {
        this._selected = pValue;
        /*  if(pValue){
              const vDomParser = new DOMParser(); 
              const vElement = vDomParser.parseFromString(this.example, "text/html");
  
              const vHead = vElement.querySelector("head");
              vHead.childNodes.forEach(node=>{
                  console.log(node);
              })
              
          }*/
        this._node.changeHtml(this.name, "PropItem");

    }

    get description() {
        if (this._options.tags && this._options.tags.hasOwnProperty("description")) {
            return this._options.tags["description"][0].description;
        }
        if (this._options.description) {
            return this._options.description;
        }

        return null;
    }

    get example() {
        if (this._options.tags && this._options.tags.hasOwnProperty("example")) {
            return this._options.tags["example"][0].description;
        }

        return `<template #${this.name}>
    <!--Some content-->
  </template>`;

        return null;
    }

    get exampleMarkdown() {
        return "```html \r\n" + this._exampleMakdown + "\r\n```";
    }

    constructor(pOptions: any) {
        this._options = pOptions;
        this.name = this._options.name;
        if (this._options.name === "default") {
            this.defaultSlot = true;
        }
        if (this._options.hasOwnProperty("title") && this._options.title == "example") {
            this._exampleMakdown = pOptions.content;
            this._type = "example"
        }
        this._node = pOptions.node;



    }
}


class PropItem {
    private _value: any;
    private _node: NodeItem;
    private _isFunction: boolean = false;
    private _isEvent: boolean = false;
    private _translate: boolean = false;
    private _options: any;
    disabled: boolean = false;
    placeholder: string;
    eventParams: Array<any>;


    get type() {
        if (this._options.type)
            return this._options.type.name;

        return "string";
    }

    get isVariable() {
        if (this._isFunction) return true;
        if (this._translate) return true;
        if (isAlwaysFunctionProp(this.name)) return true;
        if (this._options.type && this._options.type.name == "func") {
            return true;
        }

        return false;
    }


    get name() {
        if (this._isEvent) return "@" + this._options.name;
        return this._options.name;
    }

    get defaultValue() {
        if (this._options.defaultValue) {
            return this._options.defaultValue.value;
        }

        return null;
    }

    get values() {
        if (this._options.values) return this._options.values;

        return null;
    }

    set selectedValue(pValue) {
        this._value = pValue;

        if (this._node && this.name === "dataObject") {
            this._node.bindDsSelect(pValue);
        }
        this._node.changeHtml(this.name, "PropItem");
    }
    get description() {
        const vReturn = [];
        if (this._options.description) {
            vReturn.push(this._options.description);
        }
        if (this.defaultValue) {
            vReturn.push("Default: " + this.defaultValue);
        }
        if (this._options['type']) {
            vReturn.push("Type: " + this._options['type'].name);
        }

        if (vReturn.length > 0) {
            return vReturn.join(". ");
        }

        return null;
    }

    get required() {
        return this._options.requred;
    }

    get selectedValue() {
        if (this._value === this.defaultValue) {
            return null;
        }

        return this._value
    }

    get eventName() {
        return "event_" + this._options.name;
    }

    get isEvent() {
        return this._isEvent;
    }

    get code() {
        if (this.selectedValue === undefined) return null;
        if (this.selectedValue === null) return null;
        if (this.selectedValue === "") return null;
        if (this.type == "boolean") {
            if (this.selectedValue === this.defaultValue) return null;
            if (this.selectedValue == false) return null;
            if (this._isEvent) {
                return {
                    attr: this.name,
                    value: this.eventName
                }
            }
            return this.name;
        }


        return {
            attr: this.name,
            value: this.selectedValue,
            func: this.isVariable,
            event: this._isEvent,
            translate: this._translate
        };
    }

    get jsCode() {
        if (!this.code) return null;

        if (this._isEvent) {
            return {
                func: this.eventName
            }
        }
        return null;
    }

    constructor(pOptions: any) {
        this._options = pOptions;
        this._node = pOptions.node;
        if (pOptions.hasOwnProperty("event")) {
            this._isEvent = true;
        }
        if (this._node.item.value && pOptions.name === "field") {
            this._value = this._node.item.value;
        }
        //For dataobject field only , oColumn
        if (pOptions.value) {
            this._value = pOptions.value;
        }
        if (pOptions.type && pOptions.type == "Function") {
            this._isFunction = true;
        }
        if (pOptions.translate) {
            this._translate = true;
        }
        if (pOptions.hasOwnProperty("tags")) {
            if (pOptions['tags'].constructor == Array) {
                this.eventParams = pOptions['tags'].filter(x => x.title = "param");
                /*  pOptions['tags'].forEach(tag=>{
  
                      console.log(tag)
                  })*/
            } else {


                Object.values(pOptions['tags']).forEach(tag => {
                    if (tag[0]['title'] == "value" && !pOptions.value) {
                        this._value = tag[0]['description'];
                    }
                    if (tag[0]['title'] == "translate") {
                        this._translate = true;
                    }
                    if (tag[0]['title'] == "type") {
                        if (tag[0]['description'] == 'Function') {
                            this._isFunction = true;
                        }
                        //this._value = tag[0]['description'];
                    }
                    if (tag[0]['title'] == "placeholder") {
                        this.placeholder = tag[0]['description'];
                    }
                })
            }

        }
        if (pOptions.disabled) {
            this.disabled = pOptions.disabled;
        }
    }
}

